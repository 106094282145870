export default defineNuxtRouteMiddleware((to) => {
  const { isLoggedIn } = storeToRefs(useUserStore())
  const { routing } = storeToRefs(useRoutingStore())
  if (!isLoggedIn.value) {
    // if user is not logged in, two rules apply
    // onboarding/booking-request: sign_up
    // rest: sign_in
    if (to
      && (to.name?.toString().startsWith('onboarding')
      || to.name?.toString().startsWith('f-campers-id_camper-request'))
      && routing.value
    ) {
      const url = new URL(routing.value.sign_up)
      url.searchParams.append('next', to.fullPath)
      return navigateTo(url.toString(), { external: true })
    }
    return navigateTo({ name: 'login', query: { next: to.fullPath } })
  }
})
